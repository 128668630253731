import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

// Reactive Form
import { ReactiveFormsModule } from '@angular/forms';


// App routing modules
import { AppRoutingModule } from './shared/app-routing/app-routing.module';


import { AppComponent } from './app.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';

// Firebase services + enviorment module
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';

// Auth service
import { AuthService } from './shared/services/auth.service';
import { AziendaService } from './shared/services/azienda.service';
import { LoaderService } from './shared/services/loader.service';
import { ConfirmationDialogService } from './confirmation-dialog/confirmation-dialog.service';
import { BandoService } from './shared/services/bando.service';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';
import { UtenteComponent } from './components/utente/utente.component';
import { ArchivioBandiComponent } from './components/archivio-bandi/archivio-bandi.component';
import { CalcoloService } from './shared/services/calcolo.service';


@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    DashboardComponent,
    SidebarComponent,
    UtenteComponent,
    ArchivioBandiComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    FormsModule
  ],
  providers: [AuthService, AziendaService, LoaderService, ConfirmationDialogService, BandoService, CalcoloService],
  bootstrap: [AppComponent]
})
export class AppModule { }
