import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { Utente } from "src/app/shared/services/utente";
import { LoaderService } from "src/app/shared/services/loader.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';

import { QueryDocumentSnapshot } from '@angular/fire/firestore/interfaces';


@Component({
  selector: 'app-utente',
  templateUrl: './utente.component.html',
  styleUrls: ['./utente.component.css']
})
export class UtenteComponent implements OnInit {

  page = 1;
  pageSize = 10;
  collectionSize;

  subscriptionOnInit : Subscription;
  closeResult: string;
  utente: Utente;
  utenti: Utente[];
  nuovoUtenteForm: FormGroup;
  isNuovoRecord: boolean;

  constructor(
    private loaderService: LoaderService,
    private authService: AuthService,
    private modalService: NgbModal,
    private confirmationDialogService: ConfirmationDialogService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.nuovoUtenteForm = this.fb.group({
      type: ['', Validators.required ],
      displayName: ['', Validators.required ],
      email: ['', Validators.email]
   });

   this.subscriptionOnInit = this.authService.getUtenti()
      .subscribe(data => {

        this.utenti = data
          .map((item) => ({
              id: item.payload.doc.id,
              email: item.payload.doc.data()["email"],
              name: item.payload.doc.data()["name"],
              type: item.payload.doc.data()["type"]
            } as Utente
          ))
          this.collectionSize = this.utenti.length;
    });
  }

  ngOnDestroy() {
    if(this.subscriptionOnInit) {
      this.subscriptionOnInit.unsubscribe();
    } 
  }

  getUtentiByPage() {
    return this.utenti
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  open(content, isNuovoRecord: boolean, utenteId: string) {
    this.loaderService.display(true);

    this.isNuovoRecord = isNuovoRecord;
    if (isNuovoRecord) {
      this.nuovoUtenteForm.controls.displayName.setValue('');
      this.nuovoUtenteForm.controls.type.setValue('');
      this.nuovoUtenteForm.controls.email.setValue('');
    } 
    
    const modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title', 
      size: 'lg', 
      scrollable: true
    })
    
    modalRef.result.then(async (result) => {
      this.loaderService.display(true);

      try {
        this.utente = {
          id: utenteId,
          email: this.nuovoUtenteForm.value.email,
          name: this.nuovoUtenteForm.value.displayName,
          type: this.nuovoUtenteForm.value.type,
       }
  
        if(isNuovoRecord) {
         await this.authService.createUtente(this.utente);
       } else{
         await this.authService.updateUtente(this.utente);
       }

      } catch(error) {
        console.log(error);
      } finally {
        this.loaderService.display(false);
      }

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.loaderService.display(false);
  }

  modifica(content, utente: Utente) {
    this.nuovoUtenteForm.controls.type.setValue(utente.type);
    this.nuovoUtenteForm.controls.displayName.setValue(utente.name);
    this.nuovoUtenteForm.controls.email.setValue(utente.email);
    this.open(content, false, utente.id);
}

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  public openConfirmationDialog(utente: Utente) {
    this.confirmationDialogService
      .confirm("Conferma cancellazione", "Confermi la cancellazione dell'utente?")
      .then((confirmed) => confirmed ? this.delete(utente) : {})
      .catch(() => {});
  }

  public confirmRecuperaPassword(utente: Utente) {
    this.confirmationDialogService
      .confirm('Conferma operazione', 'Sicuro di voler effettuare il reset della password ?')
      .then((confirmed) => confirmed ? this.resetPassword(utente) : {})
      .catch(() => {});
  }

  resetPassword(utente: Utente){
    this.authService.forgotPassword(utente.email);
  }

  async delete(utente: Utente){
    this.loaderService.display(true);

    await this.authService.deleteUtente(utente.id);

    this.loaderService.display(false);
  }

}
