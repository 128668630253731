import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';

import { Azienda } from '../../shared/services/azienda';
import { AuthService } from "../../shared/services/auth.service";
import { AziendaService } from '../../shared/services/azienda.service';
import { LoaderService } from '../../shared/services/loader.service';
import { Calcolo } from 'src/app/shared/services/calcolo';
import { CalcoloService } from 'src/app/shared/services/calcolo.service';

import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';


import firebase from 'firebase';
import { leadingComment } from '@angular/compiler';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  aziende: Azienda[] = [];
  calcoli: Calcolo[] = [];
  inputNome: String;
  inputPercentuale: String;
  azienda_vincente: Azienda = { nome: '', percentuale: 0 } as Azienda;
  
  aziendaTable: FormGroup;

  formCalcolo: FormGroup;


  constructor(
    private modalService: NgbModal,
    public authService: AuthService,
    public aziendaService: AziendaService,
    private loaderService: LoaderService,
    public calcoloService: CalcoloService,
    private confirmationDialogService: ConfirmationDialogService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {

    this.resetForm();

    this.authService.afAuth.authState.subscribe(user => {
      if (user) {
        this.aziendaService.getCalcoli(this.authService.userData.uid).subscribe({
          next: this.loadCalcoli.bind(this)
        });
      }
    });

  }

  resetForm() {
    this.aziende = [];
    
    this.aziendaTable = this.fb.group({
      tableRows: this.fb.array([])
    });
  }

  reset() {
    this.resetForm();
    this.addRow();
  }

  loadCalcoli(data: any) {

    this.resetForm();

    this.calcoli = [];

    if(data.length > 0) {

      data.forEach(item => {
        const calcolo = {
          id: item.payload.doc.id,
          ...item.payload.doc.data()
        } as Calcolo;
    
        this.calcoli.push(calcolo);
      });
  
      this.aziende = this.calcoli[this.calcoli.length - 1].aziende;
      this.aziende.forEach(azienda => this.addRowByValue(azienda));
    }
    
  }


  async verifyCalcolo(content, warning) {
    if(this.calcoli.length >= 10)
      this.showWarningCalcolo(warning);
    else this.saveCalcolo(content);
  }

  async showWarningCalcolo(warning) {
    const modalRef = this.modalService.open(warning, {
      ariaLabelledBy: 'modal-basic-title', 
      size: 'md', 
      scrollable: true
    });

    modalRef.result
      .then((result) => {}, (reason) => {});
  }

  async saveCalcolo(content) {
    this.loaderService.display(true);

    this.createForm();

    const modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title', 
      size: 'lg', 
      scrollable: true
    });
    

    modalRef.result.then(async (result) => {
      this.loaderService.display(true);

      let calcolo = {
        aziende: this.getAziendeByForm(),
        dataInserimento: firebase.firestore.Timestamp.now(),
        name: this.formCalcolo.value.nome,
        userId: this.authService.userData.uid
      } as Calcolo;
      
      await this.aziendaService.createCalcolo(calcolo);

      this.loaderService.display(false);

    }, (reason) => {});

    this.loaderService.display(false);
  }

  createForm() {
    this.formCalcolo = this.fb.group({
      nome: firebase.firestore.Timestamp.now().toDate().toLocaleDateString(),
    });
  }


  initiateForm(): FormGroup {
    return this.fb.group({
      nome: [''],
      percentuale: ['']
    });
  }

  selectCalcolo(index) {
    this.resetForm()
    
    this.aziende = this.calcoli[index].aziende;
    this.aziende.forEach(azienda => this.addRowByValue(azienda));
  }

  deleteCalcolo(index) {
    this.aziendaService.deleteCalcolo(this.calcoli[index].id);
  
  }

  initiateFormByValue(azienda:Azienda): FormGroup {
    const form = this.fb.group({
      nome: [''],
      percentuale: ['']
    });
    
    form.controls.nome.setValue(azienda.nome);
    form.controls.percentuale.setValue(azienda.percentuale);

    return form;
  }

  addRow() {
    const control = this.aziendaTable.get('tableRows') as FormArray;
    control.push(this.initiateForm());
    this.aziende.push({} as Azienda);
  }

  addRowByValue(azienda:Azienda) {
    const control = this.aziendaTable.get('tableRows') as FormArray;
    control.push(this.initiateFormByValue(azienda));
  }

  deleteRow(index: number) {
    const control =  this.aziendaTable.get('tableRows') as FormArray;
    control.removeAt(index);
  }

  get getFormControls() {
    const control = this.aziendaTable.get('tableRows') as FormArray;
    return control;
  }

  getAziendeByForm() {
    const formArray = this.aziendaTable.get('tableRows') as FormArray;
    return formArray.controls
      .map(control => ({ 
        nome: control.value.nome, 
        percentuale: control.value.percentuale,
        taglioDelleAli: false,
        sopraSogliaAnomalia: false,
        vincente: false,
      } as Azienda));
  }


  calcola() { 
    this.loaderService.display(true);

    this.aziende = this.getAziendeByForm();

    let azienda_sorted = [...this.aziende].sort(function(a, b) {
          var keyA = a.percentuale;
          var keyB = b.percentuale;
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });

    this.azienda_vincente = this.aziendaService.lanciaRoutineAziendaVincente(azienda_sorted);
    this.loaderService.display(false);

  }

  public resetConfirmationDialog() {
    this.confirmationDialogService
      .confirm("Conferma reset datis", "Confermi il reset dei dati inseriti?")
      .then((confirmed) => confirmed ? this.reset() : {})
      .catch(() => {});
  }

}
