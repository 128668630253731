<!-- Top navigation -->
<app-sidebar></app-sidebar>

<ng-template #warning let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Chiudi" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

    <div class="modal-body">
      Numero di salvataggi superato! Elimina un calcolo prima di crearne uno nuovo.
    </div>
  </ng-template>

<ng-template #content let-modal>
  <div class="modal-header">
    <!-- <h4 class="modal-title" id="modal-basic-title" *ngIf="!modalTitleNuovo">
        <label >Copia</label>
    </h4> -->
    <button type="button" class="close" aria-label="Chiudi" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
      <form [formGroup]="formCalcolo">

          <div class="form-group">
              <label >Nome</label>:
              <input class="form-control" formControlName="nome" type="text">
          </div>

        </form>

      <button type="button" style="float: right;" class="btn btn-block btn-success" 
      (click)="modal.close('Save click')">
      <i class="fas fa-save"></i> Conferma
    </button>
  </div>

</ng-template>




<!-- Sidebar navigation -->
<div class="container-fluid">
  <div class="row">

      <nav class="col-md-3 d-md-block bg-light sidebar">
          <div class="sidebar-sticky" *ngIf="authService.userData as user">
              <div class="pt-3 pb-2 mb-3 border-bottom">
                  <h1 class="h2">Ciao <strong>{{(user.displayName) ? user.displayName : 'Utente'}}</strong></h1>
                </div>
                <!-- Show user data when logged in -->
                <div class="row">
                  <div class="col-md-12">
                        <!-- <p>User ID: <strong>{{user.uid}}</strong></p> -->
                        <p>Email: <strong>{{user.email}}</strong></p>
                        <!-- <p>Email Verified: <strong>{{user.emailVerified}}</strong></p> -->
                        <br />

                  </div>
                </div>
                  <div class="col-md-12">
                  </div>
                <table class="table ">
                  <thead>
                  <tr>
                    <th scope="col" style="font-style: italic; font-size: 16px; color:white; background:#242d3d; text-align: left;">Archivio Bandi {{(user.displayName) ? user.displayName : 'Utente'}}</th>
                    <th scope="col" style="background:#242d3d; color:white">{{calcoli.length}}</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr style="font-style: italic; font-size: 18px; text-align: center;" *ngFor="let calcolo of calcoli; let i=index" >
                      <td>{{calcolo.name}}</td>
                      <td>
                        <button type="button" 
                          class="btn btn-success" style="margin: 5px" (click)="selectCalcolo(i)">
                          <i class="fas fa-check"></i>
                        </button>
                        <button type="button" 
                          class="btn btn-danger" style="margin: 5px" (click)="deleteCalcolo(i)">
                          <i class="fas fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                
          </div>
        </nav>
  
        
   
    <!-- Main content -->
    <main role="main" class="col-md-9 ml-sm-auto col-lg-9 px-4">
      <div class="inner-adjust">

        <div class="card">
          <h4 class="card-header" style="text-align: center;  color:white; background:#242d3d;">Simulazione gara appalto prezzo più basso art. 97, comma 8, d.Lgs. n. 50/2016</h4>
          <div class="card-body">
            <form [formGroup]="aziendaTable">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Tag</th>
                  <th scope="col">Azienda</th>
                  <th scope="col">Percentuale</th>
                  <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                  <ng-container formArrayName="tableRows" *ngFor="let group of getFormControls.controls ; let i=index">
                    <tr [formGroupName]="i">
                      <th scope="row">{{ i + 1 }}</th>
                      <td>
                      <h5><span class="badge bg-success " *ngIf="this.aziende[i].vincente">
                          Vincente
                      </span></h5>
                      <h5><span class="badge bg-pill badge-danger"  *ngIf="this.aziende[i].sopraSogliaAnomalia">
                          Sopra Soglia Anomalia
                      </span></h5>
                      <h5><span class="badge bg-pill badge-warning" *ngIf="this.aziende[i].taglioDelleAli">
                          Taglio Delle Ali
                      </span></h5>

                      </td>
                      <td>
                          <input id="nome" type="text" class="form-control" placeholder="Azienda"
                            formControlName="nome" [ngModelOptions]="{standalone: true}">
                      </td>
                      <td>
                        <input id="percentuale" type="number" class="form-control" placeholder="%"
                            min="0" max="100" step="1" ng-pattern="\d+" formControlName="percentuale"
                            [ngModelOptions]="{standalone: true}"> 
                      </td>
                      <td>
                        <button type="button" 
                          class="btn btn-info" style="margin: 5px" (click)="deleteRow(i)">
                          <i class="fas fa-times"></i>
                        </button>
                      </td>
                    </tr>
                  </ng-container>


                </tbody>
              </table>

              <hr/>

              <div class="action-container">
                <button class="btn btn-info" type="submit" (click)="addRow()" style="margin-right: 5px">
                  <i class="fas fa-plus"></i> Aggiungi Azienda
                </button>

                  <button class="btn btn-danger" type="submit" (click)="resetConfirmationDialog()">
                    <i class="fas fa-trash"></i> Reset
                  </button>

                <button class="btn btn-success" [disabled]="aziendaTable.invalid" 
                  style="float: right;"
                  type="submit" (click)="verifyCalcolo(content,warning)">

                  <i class="fas fa-save"></i> Salva
                </button>

                <button class="btn btn-warning" [disabled]="aziendaTable.invalid" 
                  style="float: right; margin-right: 5px"
                  type="submit" (click)="calcola()">
                  <i class="fas fa-calculator"></i> Calcola
                </button>
              </div>
            </form>
          </div>
        </div>
        <br />

      </div>
    </main>

  </div>
</div>
  