import { Component, OnInit } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import firebase from 'firebase';

import { Bando } from 'src/app/shared/services/bando';
import { Azienda } from 'src/app/shared/services/azienda';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { BandoService } from 'src/app/shared/services/bando.service';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';
import { AuthService } from 'src/app/shared/services/auth.service';


@Component({
  selector: 'app-archivio-bandi',
  templateUrl: './archivio-bandi.component.html',
  styleUrls: ['./archivio-bandi.component.css']
})
export class ArchivioBandiComponent implements OnInit {


  page = 1;
  pageSize = 10;
  collectionSize;
  subscription: Subscription;

  formNuovoBando: FormGroup;
  formCopiaBando: FormGroup;

  filterRegione: string;

  bandi: Bando[] = [];
  bando: Bando;

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private loaderService: LoaderService,
    public authService: AuthService,
    private confirmationDialogService: ConfirmationDialogService,
    private bandoService: BandoService,) { }

  ngOnInit(): void {
    this.ricerca();

    this.createForm();
  }

  ngOnDestroy() {
    if(this.subscription) {
      this.subscription.unsubscribe();
    }  
  }

  getBandiByPage() {
    return this.bandi
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  ricerca() {
    console.log(this.filterRegione);

    this.subscription = this.bandoService.getBandi()
        .subscribe(data => {

          this.bandi = data
            .map((item) => ({
                id: item.payload.doc.id,
                nome: item.payload.doc.data()['nome'],
                regione: item.payload.doc.data()['regione'],
                aziende: item.payload.doc.data()['aziende'].map(a => a as Azienda)
              } as Bando)
            )
            .filter((item) => !this.filterRegione || (item.regione &&
              item.regione.toLowerCase().indexOf(this.filterRegione.toLowerCase()) >= 0));

          this.collectionSize = this.bandi.length;
      });
  }

  onChangeRegione(regioneValue) {
    this.filterRegione = regioneValue;
  }

  createForm() {
    this.formNuovoBando = this.fb.group({
      regione: ['', Validators.required ],
      nome: ['', Validators.required],
      aziende: this.fb.array([])
    });

    this.formCopiaBando = this.fb.group({
      regione: ['', Validators.required ],
      nome: ['', Validators.required],
    });
  }

  get getFormControls() {
    return this.formNuovoBando.get('aziende') as FormArray;
  }

  addFormAziendaRow() {
    const control = this.formNuovoBando.get('aziende') as FormArray;
    control.push(this.fb.group({
      nome: [''],
      percentuale: [''],
      vincente: [false]
    }));
  }

  deleteFormAziendaRow(index: number) {
    const control =  this.formNuovoBando.get('aziende') as FormArray;
    control.removeAt(index);
  }

  openCopia(copia) {

    this.formCopiaBando.controls.sede.setValue('');

    this.modalService.open(copia, { 
      ariaLabelledBy: 'modal-basic-title', 
      size: 'sm', 
      scrollable: true
    }).result.then(async (result) =>  {
      this.loaderService.display(true);

      const sedeId = this.formCopiaBando.value.sede;

      this.loaderService.display(false);

     }, (reason) => {
       
     });
   }

   dettaglio(contentDettaglio, bando: Bando) {

    this.bando = bando;

    const modalRef = this.modalService.open(contentDettaglio, {
      ariaLabelledBy: 'modal-basic-title', 
      size: 'lg', 
      scrollable: true
    });

    modalRef.result.then(async (result) => {

    }, (reason) => {});
  }

    modifica(content, bando: Bando) {

      this.createForm();

      this.formNuovoBando.controls.regione.setValue(bando.regione);
      this.formNuovoBando.controls.nome.setValue(bando.nome);

      if(bando.aziende) {
        const controls = (this.formNuovoBando.get('aziende') as FormArray).controls;
        bando.aziende.forEach(azienda => controls
          .push(this.fb.group({
            nome: [azienda.nome],
            percentuale: [azienda.percentuale],
            vincente: [azienda.vincente]
          }))
        );
      }

      this.open(content, false, bando.id);
    }

    nuovo(content) {
      this.createForm();

      this.open(content, true, '');
    }

    open(content, newRecord: boolean, bandoId: string) {
      this.loaderService.display(true);

      const modalRef = this.modalService.open(content, {
        ariaLabelledBy: 'modal-basic-title', 
        size: 'lg', 
        scrollable: true
      });
      
      modalRef.result.then(async (result) => {
        this.loaderService.display(true);

        const bando = {
          nome: this.formNuovoBando.value.nome,
          regione: this.formNuovoBando.value.regione,
          insertDate: firebase.firestore.Timestamp.now(),
          aziende: (this.formNuovoBando.get('aziende') as FormArray).controls
            .map(control => ({ 
              nome: control.value.nome, 
              vincente: control.value.vincente, 
              percentuale: control.value.percentuale,
              
            } as Azienda))
        } as Bando;
        
        console.log(bando);

        if(bandoId) {
          await this.bandoService.updateBando(bandoId, bando);
        } else {
          await this.bandoService.createBando(bando);
        }
        
        this.loaderService.display(false);

      }, (reason) => {});

      this.loaderService.display(false);
    }

    public openConfirmationDeleteDialog(bando: Bando) {
      this.confirmationDialogService.confirm('Conferma Operazione','Sicuro di voler eliminare il bando ?')
      .then((confirmed) => confirmed ? this.delete(bando) : {}
        ).catch(() => {});
    }

    delete(bando: Bando){
      this.loaderService.display(true);
  
      this.bandoService
        .deleteBando(bando.id)
        .finally(() => this.loaderService.display(false));  
    }
}
