import { Injectable } from '@angular/core';
import {Azienda} from '../../shared/services/Azienda';
import { AngularFirestore } from '@angular/fire/firestore';
import { Bando } from './bando';


@Injectable({
    providedIn: 'root'
  })
  
  export class BandoService {

    COLLECTION_NAME = 'bandi';

    constructor(public afs: AngularFirestore) { }

    getBandi() {
        return this.afs.collection(this.COLLECTION_NAME, ref => ref.orderBy('insertDate'))
            .snapshotChanges();
    }

    deleteBando(bandoId: string) {
        return this.afs.doc(this.COLLECTION_NAME + '/' + bandoId).delete();
    }
    
    createBando(item: Bando) {
        return this.afs.collection(this.COLLECTION_NAME).add(item);
    }

    updateBando(bandoId: string, item: Bando){
        this.afs.doc(this.COLLECTION_NAME + '/' + bandoId).update(item);
      }

  }