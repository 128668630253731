import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  subscriptionOnInit : Subscription;
  sedeSelezionataId: string;
  navbarOpen = false;

  constructor(public router: Router,
              public authService: AuthService) { }

  ngOnInit() {
  }

}
