// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBWuEuPfrAwxSJBPIScATQCJJtiU6S29Tc",
    authDomain: "appaltipa-4ce78.firebaseapp.com",
    projectId: "appaltipa-4ce78",
    storageBucket: "appaltipa-4ce78.appspot.com",
    messagingSenderId: "1096178161261",
    appId: "1:1096178161261:web:aff99be9045a28538e9164",
    measurementId: "G-TWJVJ9YQY1"
  },
  userWsUrl: 'https://us-central1-appaltipa-4ce78.cloudfunctions.net/appaltipa/user'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
