<app-sidebar></app-sidebar>


<ng-template #contentDettaglio let-modal>

    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{bando.nome}} - {{bando.regione}}</h4>
        <button type="button" class="close" aria-label="Chiudi" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
    </div>


  <div class="modal-body">

      <table class="table table-striped">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Tag</th>
            <th scope="col">Azienda</th>
            <th scope="col">Percentuale</th>
          </tr>
          </thead>
          <tbody>
              <tr *ngFor="let azienda of bando.aziende ; let i=index">
                <th scope="row">{{ i + 1 }}</th>
                <td>
                <h5><span class="badge bg-success " *ngIf="azienda.vincente">
                    Vincente
                </span></h5>
                <h5><span class="badge bg-pill badge-danger"  *ngIf="azienda.sopraSogliaAnomalia">
                    Sopra Soglia Anomalia
                </span></h5>
                <h5><span class="badge bg-pill badge-warning" *ngIf="azienda.taglioDelleAli">
                    Taglio Delle Ali
                </span></h5>

                </td>
                <td>
                    {{azienda.nome}}
                </td>
                <td>
                  {{azienda.percentuale}}
                </td>
              </tr>
          </tbody>
        </table>
        
  </div>

</ng-template>

<ng-template #content let-modal>
  <div class="modal-header">
    <!-- <h4 class="modal-title" id="modal-basic-title" *ngIf="!modalTitleNuovo">
        <label >Copia</label>
    </h4> -->
    <button type="button" class="close" aria-label="Chiudi" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
      <form [formGroup]="formNuovoBando">

          <div class="form-group">
              <label >Nome</label>:
              <input class="form-control" formControlName="nome" type="text">
          </div>
          <div *ngIf="formNuovoBando.controls['nome'].invalid && (formNuovoBando.controls['nome'].dirty || formNuovoBando.controls['nome'].touched)" class="alert alert-danger">
              <div *ngIf="formNuovoBando.controls['nome'].errors.required">
                <label >Nome obbligatorio</label>
              </div>
          </div>

          <div class="form-group">
              <select class="custom-select" formControlName="regione" >
                  <option value="">--- SELEZIONA REGIONE ---</option>
                  <option value="ABRUZZO">ABRUZZO</option>
                  <option value="BASILICATA">BASILICATA</option>
                  <option value="CALABRIA">CALABRIA</option>
                  <option value="CAMPANIA">CAMPANIA</option>
                  <option value="EMILIA-ROMAGNA">EMILIA-ROMAGNA</option>
                  <option value="FRIULI VENEZIA GIULIA">FRIULI VENEZIA GIULIA</option>
                  <option value="LAZIO">LAZIO</option>
                  <option value="LIGURIA">LIGURIA</option>
                  <option value="LOMBARDIA">LOMBARDIA</option>
                  <option value="MARCHE">MARCHE</option>
                  <option value="MOLISE">MOLISE</option>
                  <option value="PIEMONTE">PIEMONTE</option>
                  <option value="PUGLIA">PUGLIA</option>
                  <option value="SARDEGNA">SARDEGNA</option>
                  <option value="SICILIA">SICILIA</option>
                  <option value="TOSCANA">TOSCANA</option>
                  <option value="TRENTINO-ALTO ADIGE">TRENTINO-ALTO ADIGE</option>
                  <option value="UMBRIA">UMBRIA</option>
                  <option value="VALLE D'AOSTA">VALLE D'AOSTA</option>
                  <option value="VENETO">VENETO</option>
               </select>
          </div>
          <div *ngIf="formNuovoBando.controls['regione'].invalid && (formNuovoBando.controls['regione'].dirty || formNuovoBando.controls['regione'].touched)" class="alert alert-danger">
              <div *ngIf="formNuovoBando.controls['regione'].errors.required">
                <label >Regione obbligatorio</label>
              </div>
          </div>

          <table class="table table-striped">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Azienda</th>
              <th scope="col">Ribasso %</th>
              <th scope="col">Vincente</th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
              <ng-container formArrayName="aziende" *ngFor="let group of getFormControls.controls ; let i=index">
                <tr [formGroupName]="i">
                  <th scope="row">{{ i + 1 }}</th>
                  <td>
                      <input id="nome" type="text" class="form-control" placeholder="Azienda"
                        formControlName="nome" [ngModelOptions]="{standalone: true}">
                  </td>
                  <td>
                    <input id="percentuale" type="number" class="form-control" placeholder="%"
                        min="0" max="100" step="1" ng-pattern="\d+" formControlName="percentuale"
                        [ngModelOptions]="{standalone: true}"> 
                  </td>

                  <td>
                      <input type="checkbox" formControlName="vincente" [ngModelOptions]="{standalone: true}" />
                  </td>
                  <td>
                    <button type="button" 
                      class="btn btn-info" style="margin: 5px" (click)="deleteFormAziendaRow(i)">
                      <i class="fas fa-times"></i>
                    </button>
                  </td>
                </tr>
              </ng-container>


            </tbody>
          </table>

          <hr/>

          <div class="action-container">
            <button class="btn btn-info" type="submit" (click)="addFormAziendaRow()" style="margin-right: 5px">
              <i class="fas fa-plus"></i> Aggiungi Azienda
            </button>

               
            <button type="button" style="float: right;" class="btn btn-success" 
              (click)="modal.close('Save click')" [disabled]="!formNuovoBando.valid">
              <i class="fas fa-save"></i> Salva
            </button>
<!--
            <button class="btn btn-warning" [disabled]="aziendaTable.invalid" 
              style="float: right; margin-right: 5px"
              type="submit" (click)="calcola()">
              <i class="fas fa-calculator"></i> Calcola
            </button> -->
          </div> 
        </form>
  </div>
</ng-template>


<div class="container-fluid">

<div class="table-wrapper">
    <div class="table-title">
      <div class="row">
        <div class="col-sm-6">
          <h2 >Archivio Bandi</h2>
        </div>
        <div class="col-sm-6">
          <button class="btn btn-lg btn-success" (click)="nuovo(content)" style="float: right;">
            <i class="fas fa-plus"></i> Nuovo
          </button>
        </div>
      </div>
    </div>

    <br />
  
  <form class="form-inline">
    <div class="form-group">
      <label for="filterRegione">Regione</label>
      
      <select class="custom-select" (change)="onChangeRegione($event.target.value)" >
          <option value="">--- TUTTE ---</option>
          <option value="ABRUZZO">ABRUZZO</option>
          <option value="BASILICATA">BASILICATA</option>
          <option value="CALABRIA">CALABRIA</option>
          <option value="CAMPANIA">CAMPANIA</option>
          <option value="EMILIA-ROMAGNA">EMILIA-ROMAGNA</option>
          <option value="FRIULI VENEZIA GIULIA">FRIULI VENEZIA GIULIA</option>
          <option value="LAZIO">LAZIO</option>
          <option value="LIGURIA">LIGURIA</option>
          <option value="LOMBARDIA">LOMBARDIA</option>
          <option value="MARCHE">MARCHE</option>
          <option value="MOLISE">MOLISE</option>
          <option value="PIEMONTE">PIEMONTE</option>
          <option value="PUGLIA">PUGLIA</option>
          <option value="SARDEGNA">SARDEGNA</option>
          <option value="SICILIA">SICILIA</option>
          <option value="TOSCANA">TOSCANA</option>
          <option value="TRENTINO-ALTO ADIGE">TRENTINO-ALTO ADIGE</option>
          <option value="UMBRIA">UMBRIA</option>
          <option value="VALLE D'AOSTA">VALLE D'AOSTA</option>
          <option value="VENETO">VENETO</option>
       </select>
    
    </div>
    <div class="form-group">
      <button type="submit" class="btn btn-primary mx-sm-3" (click)="ricerca()">Cerca</button>
    </div>
  </form>

  <br />
  <br />

  <table class="table table-striped">
    <thead>
    <tr>
      <th scope="col "  style="font-size: 16px; color:white; background:#242d3d; text-align: left;"></th>
      <th scope="col"  style="font-size: 16px; color:white; background:#242d3d; text-align: left;">Nome</th>
      <th scope="col" style="font-size: 16px; color:white; background:#242d3d; text-align: left;">Regione</th>
      <th scope="col" style="font-size: 16px; color:white; background:#242d3d; text-align: left;"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let bando of getBandiByPage(); index as i" style="cursor: pointer;">
  
      <td>{{ i + 1}}</td>
      <td>{{ bando.nome}}</td>
      <td>{{ bando.regione}}</td>
      <td>
          <button class="btn btn-primary mr-1" (click)="dettaglio(contentDettaglio, bando)">
            <i class="fas fa-eye"></i>
          </button>
        <button class="btn btn-warning mr-1" (click)="modifica(content, bando)" *ngIf="authService.isAdmin" >
          <i class="fas fa-edit"></i>
        </button>
        <button class="btn btn-danger" (click)="openConfirmationDeleteDialog(bando)" *ngIf="authService.isAdmin"
          type="button"  >
          <i class="fas fa-trash"></i>
        </button>
      </td>
    </tr>
    </tbody>
  </table>
  </div>
  
  
  <div class="d-flex justify-content-between p-2">
    <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize">
    </ngb-pagination>
  
    <select class="custom-select" style="width: auto" [(ngModel)]="pageSize">
      <option [ngValue]="10">10</option>
      <option [ngValue]="25">25</option>
      <option [ngValue]="50">50</option>
    </select>
  </div>
</div>