<app-sidebar></app-sidebar>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" *ngIf="isNuovoRecord">
            <label >Nuovo</label>
        </h4>
        <h4 class="modal-title" id="modal-basic-title" *ngIf="!isNuovoRecord">
            <label >Modifica</label>
        </h4>
        <button type="button" class="close" aria-label="Chiudi" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="nuovoUtenteForm">
        <div class="form-group">
            <label >Email</label>:
            <input class="form-control" formControlName="email" type="text" [attr.disabled]="!isNuovoRecord ? '' : null">
        </div>
        <div *ngIf="nuovoUtenteForm.controls['email'].invalid && (nuovoUtenteForm.controls['email'].dirty || nuovoUtenteForm.controls['email'].touched)" class="alert alert-danger">
            <div *ngIf="nuovoUtenteForm.controls['email'].invalid">
            <label >Email</label> &nbsp; <label >non valida</label>.
            </div>
        </div>
        
        <div class="form-group">
            <label >Nome</label>:
            <input class="form-control" formControlName="displayName" type="text">
        </div>
        <div *ngIf="nuovoUtenteForm.controls['displayName'].invalid && (nuovoUtenteForm.controls['displayName'].dirty || nuovoUtenteForm.controls['displayName'].touched)" class="alert alert-danger">
        <div *ngIf="nuovoUtenteForm.controls['displayName'].errors.required">
            <label >Nome</label> &nbsp; <label >obbligatorio</label>.
        </div>
        </div>
        <div class="form-group">
          <label >Tipo:</label>
          <select class="custom-select" formControlName="type" >
            <option value="MEMBRO">Membro</option>
            <option value="ADMIN">Amministratore</option>
         </select>
        </div>
        
    
    
        </form>
    </div>
    <div class="modal-footer">
        <button  type="button" class="btn btn-success" (click)="modal.close('Save click')" [disabled]="!nuovoUtenteForm.valid">Salva</button>
    </div>
</ng-template>


<div class="container-fluid">

        <div class="table-wrapper">
            <div class="table-wrapper">
                <div class="table-title">
                    <div class="row">
                        <div class="col-sm-6">
                            <h2 >Gestione Utenti </h2>
                        </div>
                        <div class="col-sm-6">
                            <button class="btn btn-lg btn-success" (click)="open(content, true, '')" style="float: right;">
                                <i class="fas fa-plus"></i> Nuovo
                            </button>
                        </div>
                    </div>
                </div>

                <br />

            <table class="table table-striped">
                <thead>
                <tr>
                <th scope="col" style="font-size: 16px; color:white; background:#242d3d; text-align: left;">Nome</th>
                <th scope="col" style="font-size: 16px; color:white; background:#242d3d; text-align: left;">Email</th>
                <th scope="col"style="font-size: 16px; color:white; background:#242d3d; text-align: left;"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let utente of getUtentiByPage(); index as i">
                <td>{{ utente.name}}</td>
                <td>{{ utente.email}}</td>
                <td>
                    <button class="btn btn-primary mr-1" (click)="modifica(content, utente)"><i class="fas fa-edit"></i></button>
                    <button (click)="openConfirmationDialog(utente)" type="button" class="btn btn-danger mr-1"><i class="fas fa-trash"></i></button>
                    <button class="btn btn-info" (click)="confirmRecuperaPassword(utente)" alt="Reset password"><i class="fas fa-key"></i></button>
                </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="d-flex justify-content-between p-2">
            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize">
            </ngb-pagination>
            
            <select class="custom-select" style="width: auto" [(ngModel)]="pageSize">
                <option [ngValue]="10">10</option>
                <option [ngValue]="25">25</option>
                <option [ngValue]="50">50</option>
            </select>
        </div>

</div>
