import { Injectable } from '@angular/core';
import {Azienda} from '../../shared/services/Azienda';
import { AngularFirestore } from '@angular/fire/firestore';
import { Calcolo } from './calcolo';


@Injectable({
    providedIn: 'root'
  })
  
  export class AziendaService {

    COLLECTION_NAME = 'calcoli';

    constructor(public afs: AngularFirestore) { }

    getCalcoli(userId: string) {
        return this.afs.collection(this.COLLECTION_NAME, 
            ref => ref.where('userId', '==', userId).orderBy('dataInserimento'))
            .snapshotChanges();
    }

    deleteCalcolo(calcoloId: string) {
        //if(calcoli.length > 10) {
            //const calcoloLastId = calcoli[0].payload.doc.id;
        return this.afs.doc(this.COLLECTION_NAME + '/' + calcoloId).delete();
        //}
    }

    createCalcolo(calcolo: Calcolo) {

        // this.afs.collection(this.COLLECTION_NAME, 
        //     ref => ref.where('userId', '==', calcolo.userId).orderBy('dataInserimento'))
        //     .snapshotChanges()
        //     .subscribe({
        //         next: this.deleteCalcolo.bind(this)
        //     });

        return this.afs.collection(this.COLLECTION_NAME).add(calcolo);
    }
    


    calcoloTaglioDelleAli(azienda_lista: Azienda[]) {
        const numero_taglioali =  Math.ceil(azienda_lista.length * 0.1)
        const primi_taglioali = azienda_lista.slice(0, numero_taglioali)
        const ultimi_taglioali = azienda_lista.slice(azienda_lista.length - numero_taglioali, azienda_lista.length)
        const aziende_senza_ali = azienda_lista.slice(numero_taglioali, azienda_lista.length - numero_taglioali)
        azienda_lista.map(x => {
            if (primi_taglioali.includes(x) || ultimi_taglioali.includes(x)){
                x.taglioDelleAli = true;
            }else{
                x.taglioDelleAli = false;
            }
        });
        for (const element of azienda_lista) {
          console.log(element.nome);
          console.log(element.taglioDelleAli);
        }
        return aziende_senza_ali;
    }

    mediaEsclusoTaglioDelleAli(azienda_lista: Azienda[]) {
        const media = azienda_lista.reduce((total, next) => total + next.percentuale, 0) / azienda_lista.length;
        return media;
    }

    sommaEsclusoTaglioDelleAli(azienda_lista: Azienda[]) {
        const somma = azienda_lista.reduce((total, next) => total + next.percentuale, 0);
        return somma;
    }

    mediaRibassiMaggiori(azienda_lista: Azienda[], media: number) {
        const aziende_ribassi_maggiori = azienda_lista.filter(x => x.percentuale > media);
        const scarti = aziende_ribassi_maggiori.map( x => x.percentuale - media);
        const media_scarti = scarti.reduce((total, next) => total + next, 0) / scarti.length;
        return media_scarti;
    }

    calcoloSogliaAnomalia(numeroofferte: number, sommaesclusotaglioali: number, mediaesclusotaglioali: number, mediaribassimaggiori: number) {
        if (numeroofferte >= 15)
        {
            const string_pdv = sommaesclusotaglioali.toString().split(".")[1];
            console.log('split:' + string_pdv);
            const pdv1 = Number(string_pdv[0]);
            const pdv2 = Number(string_pdv[1]);
            const pdv_prodotto = pdv1 * pdv2 /100;
            console.log('pdv1:' + pdv1);
            const dsm = pdv_prodotto * ( mediaribassimaggiori);
            console.log('DSM:' + dsm);
            return ( mediaribassimaggiori + mediaesclusotaglioali) - dsm;
        }
        else
        {
            const rapporto = mediaribassimaggiori / mediaesclusotaglioali;
            console.log('rapporto' + rapporto);
            if (rapporto > 0.15)
            {
                return mediaesclusotaglioali + mediaribassimaggiori;
            }
            else
            {
                return mediaesclusotaglioali * 1.2;
            }
        }
    }

    prendiAziendaVincente(sogliaanomalia: number, azienda_lista: Azienda[]): Azienda {
        console.log('soglia anomalia:' + sogliaanomalia);
        const aziende_finale = azienda_lista.filter(x => x.percentuale < sogliaanomalia);
        azienda_lista.map(x => {
            if (x.percentuale > sogliaanomalia){
                x.sopraSogliaAnomalia = true;
            }else{
                x.sopraSogliaAnomalia = false;
            }
        });
        const minimo_ribasso_oltre_soglia = Math.max( ...aziende_finale.map(x => x.percentuale));
        console.log('minimo:' + minimo_ribasso_oltre_soglia);
        const azienda_finale = aziende_finale.filter(x => x.percentuale === minimo_ribasso_oltre_soglia);
        azienda_lista.map(x => {
            if (x.percentuale === minimo_ribasso_oltre_soglia){
                x.vincente = true;
            }else{
                x.vincente = false;
            }
        });
        console.log('azienda:' + azienda_finale[0].nome);
        return azienda_finale[0];
    }


    lanciaRoutineAziendaVincente(aziende: Azienda[]) {
        let azienda_lista = [...aziende];
        console.log('New Calculation');
        const numero_offerte = azienda_lista.length;

        for (const element of azienda_lista) {
          console.log(element.nome);
          console.log(element.percentuale);
        }
        console.log('Numero Offerte:' + numero_offerte);
        const aziende_senza_ali = this.calcoloTaglioDelleAli(azienda_lista);
        for (const element of aziende_senza_ali) {
          console.log(element.nome);
          console.log(element.percentuale);
        }
        console.log('aziende daglio delle ali')
        for (const element of azienda_lista) {
          console.log(element.taglioDelleAli);
          console.log(element.nome);
        }
        const media_senza_ali = this.mediaEsclusoTaglioDelleAli(aziende_senza_ali);
        console.log('MAR:' + media_senza_ali);

        const somma_senza_ali = this.sommaEsclusoTaglioDelleAli(aziende_senza_ali);
        console.log('SRP' + somma_senza_ali);

        const media_ribassi_maggior = this.mediaRibassiMaggiori(aziende_senza_ali, media_senza_ali);
        console.log('SMA' + media_ribassi_maggior);

        const soglia_anomalia = this.calcoloSogliaAnomalia(numero_offerte, somma_senza_ali,media_senza_ali,media_ribassi_maggior);
        console.log('SSM' + soglia_anomalia);

        const azienda_vincente = this.prendiAziendaVincente(soglia_anomalia,azienda_lista);
        console.log('Azienda vincente' + azienda_vincente.nome);
        return azienda_vincente;

        }
  }