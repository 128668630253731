import { Injectable, NgZone } from '@angular/core';
//import { auth, analytics } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { Utente } from "./utente";


@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: any; // Save logged in user data
  credentialData: any;

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    private http: HttpClient,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
  ) {

    /* Saving user data in sessionStorage when
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        sessionStorage.setItem('user', JSON.stringify(this.userData));
        
        const subscription = this.getUserDetails(user.email)
          .subscribe(result => {
            const type = result[0].payload.doc.data()["type"];

            sessionStorage.setItem('type', type);
            subscription.unsubscribe();
        });

        

      } else {
        sessionStorage.setItem('user', null);
        sessionStorage.setItem('type', null);
      }
    });
  }

  // Sign in with email/password
  SignIn(email, password) {

    this.afAuth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        
        sessionStorage.setItem('user', JSON.stringify(result.user));
        this.router.navigate(['/dashboard']);

      }).catch((error) => {
        if(error.code === 'auth/user-not-found') {
          window.alert('Utente non trovato!');
        } else {
          window.alert('Errore generico. Riprova più tardi!');
        }
         
      });

      
  }

  getUserDetails(email) {
    
    return this.afs.collection('users', ref => ref
      .where('email', '==', email))
      .snapshotChanges();
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(sessionStorage.getItem('user'));
    // return (user !== null && user.emailVerified !== false) ? true : false;
    return (user !== null) ? true : false;
  }

  get isAdmin(): boolean {
    const type = sessionStorage.getItem('type');
    return (type === 'ADMIN') ? true : false;
  }

  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      sessionStorage.removeItem('user');
      this.router.navigate(['sign-in']);
    });
  }

  // Reset Forggot password
  forgotPassword(passwordResetEmail) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
      //window.alert(this.locationService.translate('mailPassword'));
    }).catch((error) => {
      window.alert(error)
    })
  }

  getUtenti() {
    return this.afs.collection('users')
      .snapshotChanges();
  }

  getUtente(userId: string) {
    return this.afs.collection('users')
      .doc(userId)
      .get();
  }

  async createUtente(utente: Utente) {
    const idToken = await this.generateToken();

    const httpOptions = {
      headers: new HttpHeaders ({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + idToken
      })
    };

    return this.http.post(environment.userWsUrl, utente, httpOptions).toPromise();
  }

  async generateToken(){
    const currentUser = await this.afAuth.currentUser;
    const idToken = await currentUser.getIdToken();

    return idToken;
  }

  updateUtente(utente: Utente){
    this.afs.doc('users/' + utente.id).update(utente);
  }

  async deleteUtente(utenteId:string) {

    const idToken = await this.generateToken();

    const httpOptions = {
      headers: new HttpHeaders ({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + idToken
      })
    };

    return this.http.delete(environment.userWsUrl + '/'  + utenteId, httpOptions).toPromise();

  }
}
