import { Injectable } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


import {Azienda} from '../../shared/services/Azienda';
import { AngularFirestore } from '@angular/fire/firestore';
import { Bando } from './bando';
import { Calcolo } from './calcolo';


@Injectable()
export class CalcoloService {

  constructor(private modalService: NgbModal,
    public afs: AngularFirestore) { }
  
  COLLECTION_NAME = 'calcoli';


  getBandi() {
    return this.afs.collection(this.COLLECTION_NAME, ref => ref.orderBy('insertDate'))
        .snapshotChanges();
}

deleteBando(calcoloId: string) {
    return this.afs.doc(this.COLLECTION_NAME + '/' + calcoloId).delete();
}

createBando(item: Bando) {
    return this.afs.collection(this.COLLECTION_NAME).add(item);
}

updateBando(bandoId: string, item: Calcolo){
    this.afs.doc(this.COLLECTION_NAME + '/' + bandoId).update(item);
  }

}
