<section class="container">
    <section class="login-form">
        <form [formGroup]="loginForm" role="login" style="background: #242d3d">
          <img src="assets/images/logo.jpeg" class="img-responsive" alt="" />
            <input type="email" class="form-control input-lg" formControlName="email" #email required placeholder="Email">
            <input type="password" class="form-control input-lg"  formControlName="password" #password required placeholder="Password">
            <button type="submit" (click)="authService.SignIn(email.value, password.value)" class="btn btn-lg btn-success btn-block">
              <label >Login</label>
            </button>
        </form>
    </section>
    </section>
  
