import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Required components for which route services to be activated
import { SignInComponent } from 'src/app/components/sign-in/sign-in.component';
import { DashboardComponent } from 'src/app/components/dashboard/dashboard.component';
import { UtenteComponent } from 'src/app/components/utente/utente.component';
import { ArchivioBandiComponent } from 'src/app/components/archivio-bandi/archivio-bandi.component';

// Import canActivate guard services
import { AuthGuard } from '../../shared/guard/auth.guard';
import { SecureInnerPagesGuard } from '../../shared/guard/secure-inner-pages.guard';



const routes: Routes = [
  { path: '', redirectTo: '/sign-in', pathMatch: 'full'},
  { path: 'sign-in', component: SignInComponent, canActivate: [SecureInnerPagesGuard]},
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'utenti', component: UtenteComponent, canActivate: [AuthGuard] },
  { path: 'archivio-bandi', component: ArchivioBandiComponent, canActivate: [AuthGuard] },
  
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]

})
export class AppRoutingModule { }
