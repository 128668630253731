<nav class="navbar navbar-icon-top navbar-expand-lg navbar-dark bg-dark" style="background-color: #242d3d!important;">
  <a class="navbar-brand" href="#">
    <img src="assets/images/logo.jpeg" alt="">
  </a>

  <div class="collapse navbar-collapse"
       [ngClass]="{ 'show': navbarOpen }" id="navbarSupportedContent">

       <ul class="navbar-nav mr-auto">

    <li class="nav-item" *ngIf="authService.isAdmin">
      <a class="nav-link" [routerLink]="['/utenti']" routerLinkActive="active">
        <i class="fas fa-users"></i><label style="color: white; font-weight: bold">Utenti</label>
      </a>
    </li>

    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/archivio-bandi']" routerLinkActive="active">
        <i class="fas fa-archive"></i><label style="color: white; font-weight: bold">Archivio Bandi</label>
      </a>
    </li>

    </ul>
<div style="float: left; font-weight: bold; margin-left:5px;" *ngIf="authService.userData as user">
  <!-- <label style="color: white">Benvenuto</label> {{user.displayName}} -->
  <button type="button" class="btn btn-danger btn" (click)="authService.SignOut()">
    <i class="fas fa-sign-out-alt">Logout</i>
  </button>
</div>

  </div>
</nav>
<br />


